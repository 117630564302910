
@font-face {
  font-family: 'Inter';
  src: url('./Inter.ttf') format('truetype');
}

html {
  scroll-behavior:smooth;
}

html *
{     
   font-family: 'Inter' !important;    
}


body {
  margin: 0 !important;
  /* position: relative; */
  /* overflow-x:hidden; */
  max-width: 100%;
}


/* html:focus-within {
  scroll-behavior: smooth;
} */

.reveal{
  /* position: block; */
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
  overflow: hidden;
}
.reveal.active{
  transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
/* 
section, .greySections {
  scroll-behavior: smooth; 
} */


#navbar {
  /* background-color: #F2F5F7; */  
  display: flex;
  list-style: none;
  align-items: center;
  max-width: 100%;
  top: 0;
  position: relative;
  z-index: 999999;
  background-color: white;
  width: 100%;
}

.content {
  padding: 16px;
} 

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
/* .sticky + .content {
  padding-top: 60px;
} */


#navbar ul {
  margin:0;
  display: flex;
  width: 100%;
  align-items: center;
}

#navbar ul li {
  margin: 1em;
  list-style: none;  
}

/* navbar-logo {
  width: inherit;
  height: auto;
} */

.nav-item {
  text-decoration: none;
  color: black;
}

.nav-item:hover {
  cursor: pointer;
  background-color: #C3CFD9;
  padding: 0.5em;
  border-radius: 0.5em;
}

button {
  cursor:pointer;
}

.greySections {
  background-color: #F2F5F7;
  display: flex;
  padding: 5em 4em;
  justify-content: center;
  font-size: larger;
}

.gsCol {
  text-align: center;  
  flex-direction: column;
}

footer {
  background-color: #F2F5F7;
  padding: 3em;
  display: flex;
  justify-content: space-between;
}

.footer-icon {
  margin: 0.5em;
}

.footer-icon:hover {
  cursor: pointer;
}

ul {
  list-style: none;
}

/* landing */

#landing {
  max-width: 100%;
  /* background-image: url("./images/background.jpg");
  background-position: center;
  background-size: cover; */
  display: flex;  
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* #landing div {
  display: flex;
  flex-wrap: wrap;
  
} */

#landingText {  
  padding: 1em;
  justify-content: center;
  align-items: left;
  flex: 1 1 0;
  height: auto;    
  flex-direction: column;
  /* flex-wrap: wrap; */
  max-width: 100%;
}

#landingImage {
  display: flex;
  /* flex-wrap: wrap; */
  flex: 1.5 1 0;
}

.yellowBtn {
  background-color: #F7C325;
  border: none;
  border-radius: 0.5em;
  white-space: nowrap;
  font-size: large;
  text-align: center;
  width: fit-content;
  padding: 0.8em;
}


#landingImage img {
  max-width: 100%;
  height: auto;
}

#landing ul {
  padding-inline-start: 0;
}

#landing ul li {
  margin-bottom: 1em;
}

#landing ul li:before {
  content: "✓";
}

/* landing end */

/* solutions img */

#solutions {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4em;
}

#solutions div{
  display: flex;  
  padding: 1em;
}

.solutionsText {
  flex-direction: column;
  padding: 1em;
  flex: 2 1 0;
}

.solutionsText h2 {
  text-transform: uppercase;
}

.solutionsImageDiv {
  flex: 1 1 0;
}

.solutionsImage {
  width: 100%;
  height: auto;
  transition: 1s all ease;
}

/* solutions end */

/* services start */
#services {
  height: auto;
  display: flex;
  justify-content: center;
  padding: 5em 1em;
}

.servicesDiv {
  margin: 1em 4em;
  border: 0.2em solid #cfd0d1;
  padding: 5em 3em;
  flex: 1 1 0;
}

#services ul {
  margin-top: 2em !important;
  list-style: disc;
}

.servicesDiv ul li {
  margin-bottom: 1em;
}

.servicesDiv span {
  margin-bottom: 3em;
  margin-left: 1em;
}

/* services end */
/* about us */

#aboutUs {
  display: flex;
  flex-direction: column;
  padding-top: 4em;
}

#auTitle {
  text-align: center;
}

.auBodyDiv {
  display: flex;
  padding: 1em;
  margin: 3em 1em;  
}

.auTextDiv {
  display: flex;
  flex: 1 1 0;
}

.auTextDiv h4{
  margin-top: 0;
}

.auTextDiv div {
  margin: 0.5em;
}

#clients {
  display: flex;
  justify-content: center;
  align-self: center;
  /* padding: 2em; */
  background-color: #F2F5F7;
  width: 100%;
  align-items: center;
}

.mgRight {
  margin-right: 1em;
}

.au-icon {
  background-color: #CE410D;
  padding: 0.8em;
  border-radius: 0.5em;
  
}

/* about us end */
/* careers  */
#careers {
  flex-direction: column;  
}

#careers h2 {
  text-align: center;
}



#careerIntro {
  display: flex;
  max-width: 100%;
  padding: 4em 1em;
  align-items: center;
}

.careersBody, .careersImage {
  margin: 1em;
}

.careersImage {
  flex:1 1 0;
  justify-content: center;
}

.careersBody {
  flex: 2 1 0;
}

.careerDiv {
  display: flex;
}

.careerDiv div {
  margin: 1em;
}

.crText h4 {
  margin-top: 0.8em;
  text-transform: uppercase;
}


.crImageIcon {
  background-color: #C3CFD9;
  padding: 1em;
  border-radius: 100%;    
}
/* careers end */
/* career portal */

#careerPortal {
  background:linear-gradient(0deg, rgb(255 182 37 / 72%), rgb(225 191 0 / 72%)), url(./images/BuzzyExypnosBGC-87.jpg);
  background-size:cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 2em;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 80vh;
  color: white;
}

.cpButton {
  /* width: 20vw; */
  padding: 0.8em 2em;
  background-color: white;
  border: none;
  border-radius: 0.5em;
  font-size: large;
}


#careerPortal h1 {
  font-size: xx-large;
}


/* career portal end */
/* contact us */

#contactUsSection {
  display: flex;
  max-width: 100%;
}

.contactUsImage  {
  display: flex;
  width: 100%;
}

.cuContent {
  display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    padding: 2em;
}

.cuFormNames {
  display: flex;
}

.cuForm2 {
  display: flex;
  flex-direction: column;
}


.nameDiv {
  display: flex;
  flex-direction: column;
  width: 100%;  
}

.nameDiv:first-child {
  margin-right: 1em;
}

.cuFormNames .form-item:first-child{
  margin-right: 1em;  
  
}


.form-item {
  margin-bottom: 1em;
}

#contactUs .yellowBtn {
  width: auto;  
}

#formBtn {
  float: right;
}

.input-icons i {
  position: absolute;
  /* margin-top: 0.5em; */
  margin: 1.2em 0.5em;
  padding: 0.5em 0em;
}

.input-icons {
  width: 100%;
  /* margin-bottom: 10px; */
}

.icon {
  padding: 10px;
  min-width: 40px;
}

#subject {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.iconSpacing {
  padding-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}



/* contact end */


.careerGS{
  padding-left: 5em; 
  padding-right: 5em;
}

#navbarMobile {
  display: none;
}

.nav-mobile {  
  overflow: hidden;
  position: fixed;    
  width: 100vw;
  top: 0;  
  background-color: white;
  /* transition: 1s; */
  flex-wrap: wrap;
  z-index: 9999;  
}

.navbarItems {
 display: flex; 
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.navListStyle {
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-size: larger;
  padding: 1em;
}

.navListStyle a {
  text-decoration: none;
  color: black;
  margin: 0.5em;
}


@media only screen and (max-width: 768px) { 

  #navbar {
    display: none;
  }

  .reveal {
    transform: none;
  }

  .nav-mobile {
    display: flex !important;
  }

  
  .mb-navbar {
    display: block;
    position: fixed !important;
    top: 0;
  }

  section, section div{
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 !important;  
    height: auto;  
  }
  
  #landing, #solutions, #services, #careerIntro {
    margin: 1em;
    max-width: 100%;    
  }

  .servicesDiv {
    display: flex;
    width: 100%;
    height: auto;    
  }

  #services {
    margin: 1em;
    max-width: 100%;
    height: auto;
  }

  .servicesDiv ul {
    display: flex;
    flex-wrap: wrap;    
    list-style-type: disc;
    margin-top: 0 !important;
  }

  .servicesDiv span {
    margin-top: 1em !important;
    margin-bottom: 0;
  }

  section div {
    margin: 0.5em 0em;
    width: 100%;
  }

  #landing{
    padding: 16px 0px;
    margin: 3em 0em;
    /* margin-top: 3em !important;   */
  }

  .greySections {
    text-align: center;
    padding: 1em 0em;
    max-width: 100%;
  }


  #landingText {
    padding: 0 !important;
    margin: 3em 0em;
    max-width: 100%;
    /* padding: 1em; */
    height: auto;
    flex-direction: column;
  }

  #landingList {
    margin: 1em;
  }

  .servicesDiv {
    margin: 1em 0em;
  }

  #aboutUs {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }

  .navItems {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* #landingText h3{
    margin-left: 1em;
    margin-right: 1em;
  } */

  .navbar-mobile a, .navbar-mobile img {
    position: block;
  }


  .fixed {
    position: fixed;
  }

  .navListStyle {
    display: none;    
  }

  #clients {
    text-align: center;
    padding: 0.5em !important;
    width: 100%;
    margin-bottom: 0;
  }

  .mgRight {
    margin-right: 0;
  }

  footer {
    padding: 1.5em;
    align-items: center;
    max-width: 100%;
  }

  #careerPortal div {  
    margin: 2em;
  }

  #careerPortal h2 {
    margin-left: 2em;
    margin-right: 2em;
  }
}




